<template>
  <div>
    <van-nav-bar left-arrow
                 :title="$t('notice.信息查看')"
                 fixed
                 @click-left="$router.go(-1)" />

    <div style="padding-top: 56px;">
      <p class="title">{{entity.documentTitle}}</p>
      <p class="date">{{(entity.pubDate)? entity.pubDate.substring(0,10):''}}</p>
      <div class="newsCon"
           v-html="entity.content">{{entity.content}}</div>

      <filesList :filesList="filesList"></filesList>
    </div>
  </div>
</template>

<script>
import approval from '@/components/approval/approval';
import filesList from '@/views/business/components/filesList';

export default {
  name: 'detail',
  components: {
    filesList,
  },
  comments: {

  },
  data () {
    return {
      entity: {
        id: '',
        documentTitle: '',
        documentType: '',
        documentEnable: '',
        documentDesc: '',
        content: '',
      },
      filesList: [],
    };
  },
  methods: {
    backfilleditData () {
      var dataId = this.entity.id;
      if (dataId) {
        this.$myHttp({
          method: 'post',
          url: '/microarch/sys/SysDocument/viewNews',
          data: {
            'entity': {
              'id': dataId
            }
          }
        }).then(res => {
          var backData = res.data.entity;
          this.entity = backData;
          this.attachList = backData.filesList ? backData.filesList : [];
        });
      }
    },
  },
  created () {
    this.entity.id = this.$route.query.id;
    this.backfilleditData();
  },
  mounted () {

  }
};
</script>

<style scoped lang="less">
.title,
.date {
  text-align: center;
}
.title {
  font-size: 18px;
  color: #333;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 10px;
  padding: 0 10px;
}
.date {
  color: #646566;
  font-size: 14px;
  margin-bottom: 10px;
}
.newsCon {
  width: 100%;
}
</style>
<style>
.newsCon p {
  padding: 0 10px !important;
  background-color: #eee !important;
  text-indent: 0 !important;
}
.newsCon p > span {
  display: inline-block;
}
.newsCon img {
  width: 100% !important;
}
</style>
