<template>
  <div style="margin-top:10px;">
    <div class="files-panel flex-ul" style="padding:0;" v-if="showFile">
      <van-collapse v-model="activeName" accordion>
        <van-collapse-item name="1">
          <template #title>
            <div style="display: flex;justify-content: space-between;">
              <span style="font-size:16px;">附件</span>
              <span v-if="filesShowList.length == 0">无</span>
              <!-- <van-uploader accept="*/*"
                            :after-read="fileAfterRead"
                            v-if="uploadFile">
                <van-button type="info"
                            size="small">上传附件</van-button>
              </van-uploader> -->
            </div>
          </template>

          <ul class="files-list">
            <li v-for="(item, index) in filesShowList" :key="index">
              <div class="file-name">{{ item.attachmentName }}</div>
              <div>
                <span class="size">{{ item.fileSizeName }}</span>
                <van-button
                  round
                  plain
                  type="info"
                  size="small"
                  class="download-link"
                  @click="downloadFile(item.id, item.attachmentName, item.fileSize)"
                  >下载
                </van-button>
              </div>
            </li>
          </ul>
        </van-collapse-item>
      </van-collapse>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilesList',
  components: {},
  props: {
    filesList: {
      type: [Array],
      default: function() {
        return [];
      }
    },
    uploadFile: {
      type: [Boolean],
      default: false
    },
    showFile: {
      type: [Boolean],
      default: false
    },
    fileParams: {
      type: [Object],
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      activeName: '1',
      filesShowList: [],
      filesDataList: []
    };
  },
  watch: {
    filesList(newVal) {
      this.filesDataList = newVal.map(item => {
        return { ...item };
      });
      this.renderFileList();
      return newVal;
    }
  },
  created() {
    this.filesDataList = this.filesList.map(item => {
      return { ...item };
    });
    this.renderFileList();
  },
  methods: {
    renderFileList() {
      this.filesShowList = this.filesDataList.map(item => {
        let size = +item.fileSize;
        size = size / 1024;
        if (size > 1024) {
          size = (size / 1024).toFixed(2) + 'MB';
        } else {
          size = size.toFixed(2) + 'KB';
        }
        return {
          ...item,
          fileSizeName: size
        };
      });
    },

    fileAfterRead(content) {
      console.log('fileAfterRead');
      console.log(content);
      let fd = new FormData();
      fd.append('file', content.file);
      fd.append('id', content.data ? content.data.id : null);
      /*
        fd.append('attachmentType', content.data.attachmentType);
        fd.append('attachmentDesc', content.data.attachmentDesc);
        fd.append('id', content.data.id);
        */
      fd.append('businessId', this.fileParams.bisinessId);
      fd.append('uploadPath', this.fileParams.uploadPath);
      fd.append('fileName', content.file.name);
      fd.append('dataList', JSON.stringify(this.filesList));
      fd.append('fileSize', content.file.size);

      this.$myHttp({
        url: '/hrpm/sysAttchement/upload',
        method: 'post',
        data: fd,
        onUploadProgress: progressEvent => {
          console.log((event.loaded / event.total) * 100);
          // content.file.percent = event.loaded / event.total * 100
          // content.onProgress(content.file)
        }
        // headers: {'Authorization': 'Sys ' + sessionStorage.getItem('token')}
      }).then(res => {
        if (res.status == 200) {
          this.filesDataList = res.data.dataList;
          this.renderFileList();
          this.$emit('uploadHandle', res.data.dataList);
        }
      });
    },
    // 下载文件
    downloadFile(id, fileName, fileSize) {
      this.$myHttp({
        method: 'post',
        url: '/microarch/sys/sysAttchement/download',
        data: {
          entity: {
            id: id
          }
        },
        responseType: 'arraybuffer'
      }).then(res => {
        // 成功回调
        var headers = res.headers;
        console.log('headers---', res.headers);
        var blob = new Blob([res.data], {
          type: headers['content-type']
        });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        // firefox 里面触发
        var browser = navigator.userAgent.toLowerCase();
        if (browser.indexOf('firefox') > -1) {
          var event = document.createEvent('MouseEvents');
          event.initEvent('click', true, true);
          link.dispatchEvent(event);
        }
      });
    }
  },
  mounted() {
    this.businessKey = this.$route.query.businessKey;
  }
};
</script>

<style scoped lang="less">
.file-name,
.size {
  color: #323233;
  font-size: 16px;
}
</style>
