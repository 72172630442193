<template>
  <div
    :style="{ width: forwardBtn && reverseBtn ? '66.6666%' : 'auto', flex: forwardBtn && reverseBtn ? 'auto' : '1' }"
  >
    <div v-if="taskType === 'waitfor' || submitVisable" class="approve-btn" style="display:flex;">
      <van-button
        v-if="forwardBtn"
        size="small"
        type="primary"
        style="background:rgb(67, 120, 190);border-color:rgb(67, 120, 190);"
        @click="transactProcess(false)"
      >{{ forwardBtnName }}
      </van-button
      >
      <van-button
        v-if="reverseBtn"
        size="small"
        type="primary"
        style="background:rgba(234, 105, 105, 1);border-color:rgba(234, 105, 105, 1);"
        @click="transactProcess(true)"
      >{{ reverseBtnName }}
      </van-button
      >
    </div>

    <div v-if="taskType === 'transated'" class="approve-btn">
      <van-button v-show="transatedInfo.callBack" size="small" type="primary" @click="callBack()">取回</van-button>
    </div>
  </div>
</template>

<script>
  import { Toast } from 'vant';

  export default {
    name: 'approval',
    props: {
      businessInfo: {}, //业务详情数据
      customSubmitBtnName: {
        // 自定义审批按钮名称
        type: Array,
        default: () => []
      },
      processName: {
        type: String,
        default: ''
      },
      applyRefs: {
        type: Object,
        default: function() {
          return {};
        }
      },
      submitVisable: {
        type: Boolean,
        default: false
      },
      woCodeValue: {
        type: String,
        default: ''
      },
      saveUrl: {
        type: String,
        default: ''
      },
      taskId: {
        type: String,
        default: ''
      },
      detailPageName: '', // 返回或取消是 如符合此路由 name 属性则会调用取消审批流程接口后再 next()
      applyChangeNo: {
        //变更页面原始数据
        type: String,
        default: ''
      }
    },
    data() {
      return {
        radio: '1',
        opinion: '',
        process: false,
        approval: false,
        isBack: false,
        forwardBtn: true,
        reverseBtn: true,
        commonOpinions: [],
        transactData: {},
        transatedInfo: {},
        waitforInfo: {},
        forwardBtnName: this.customSubmitBtnName[0] || '提交审批',
        reverseBtnName: this.customSubmitBtnName[1] || '撤销审批',
        detail: {
          id: ''
        }, // 单据信息 List页面跳转单据详情页面的数据
        taskType: '', // 类型：waitfor  transated
        woCode: ''
      };
    },
    mounted() {
    },
    watch: {
      woCodeValue: {
        handler(newName, oldName) {
          if (newName) {
            this.woCode = newName;
          }
        },
        // 代表在wacth里声明了firstName这个方法之后立即先去执行handler方法
        immediate: true
      },
      taskId: function(curVal, oldVal) {
        this.detail.id = curVal;
      },
      reverseBtn: function(curVal, oldVal) {
        if (curVal && this.forwardBtn) {
          this.$emit('changeReverseBtn');
        }
      }
    },
    created() {
      this.detail.id = this.taskId;
      this.taskType = this.$route.query.type;

      if (this.submitVisable) {
        this.reverseBtn = false;
      }
      // console.log(this.taskType)
      this.initPage();
    },
    methods: {
      initPage() {
        if (this.taskType === 'waitfor') {
          this.getWaitforTask();
        } else if (this.taskType === 'transated') {
          this.getTransated();
        }
      },
      getWaitforTask() {
        this.$myHttp({
          method: 'GET',
          url: '/microarch/activiti/task/waitfor/' + this.detail.id
        }).then(res => {
          this.waitforInfo = res.data;
          //当前节点为发起人，提交按钮显示'提交审批'
          if (this.waitforInfo.activityId === 'apply') {
            this.forwardBtnName = '提交审批';
            this.reverseBtn = false;
          }

          //当前节点为会签节点时，隐藏反向审批按钮
          if (this.waitforInfo.isCountersign) {
            this.reverseBtn = false;
          }
          this.detail = res.data;
          this.getBtnConfig();
        });
      },
      getBtnConfig() {
        this.$myHttp({
          method: 'GET',
          url: '/microarch/activiti/btnConfig2?taskId=' + this.detail.id + '&taskType=waitfor'
        }).then(res => {
          if (res.data.repelEnable) {
            this.reverseBtn = true;
          }
        });
      },
      getTransated() {
        this.$myHttp({
          method: 'GET',
          url: '/microarch/activiti/task/transated/' + this.detail.id
        }).then(res => {
          this.transatedInfo = res.data;
          this.detail = res.data;
        });
      },

      //取回
      callBack() {
        this.$dialog
          .confirm({
            title: '确认取回？'
          })
          .then(() => {
            // on confirm
            let params = {
              optionType: 'callBackProcess',
              optionText: '取回',
              taskId: this.transatedInfo.taskId,
              processInstanceId: this.detail.pid,
              nextId: this.detail.activityId,
              priority: '50',
              withdrawId: this.transatedInfo.id,
              userName: sessionStorage.getItem('userName'),
              candidateUserIds: {
                [this.detail.activityId]: [sessionStorage.getItem('userName')]
              }
            };
            this.$myHttp({
              method: 'POST',
              url: '/microarch/activiti/callBack',
              data: params
            }).then(res => {
              // this.syncProcess('callBackProcess');
              this.$router.replace({
                name: 'workFlow/workFlowList',
                type: 'transated'
              });
              this.$emit('process-callback', params.processInstanceId, 'callBackProcess');
            });
          });
      },
      transactProcess(back) {
        if (this.applyRefs.form && !back) {
          this.applyRefs.form
            .validate()
            .then(res => {
              this.transactProcessHandle(back);
            })
            .catch(err => {
            });
        } else {
          this.transactProcessHandle(back);
        }
      },
      async transactProcessHandle(back) {
        let option = back ? 'return' : 'submit';
        this.isBack = back;
        if (option == 'return' && this.reverseBtnName == '撤销审批') {
          this.repelProcess();
        } else if (this.taskType) {
          if (option == 'submit' && !this.detail.id) {
            this.getWorkflow(option);
          } else {
            this.awaittransactProcess(option);
          }
        } else {
          this.$emit('saveApplyHandle', false, () => {
            if (option == 'submit' && !this.detail.id) {
              this.getWorkflow(option);
            } else {
              this.awaittransactProcess(option);
            }
          });
        }
      },
      /**
       * 撤销审批
       * 删除流程信息 业务表单从流程中剥离
       * */
      repelProcess() {
        var param = {};
        param.optionType = 'repelProcess';
        param.optionText = 'revocationProcess';
        this.$dialog
          .confirm({
            title: '撤销审批',
            message: '确认撤销流程？'
          })
          .then(() => {
            // on confirm
            this.$myHttp({
              method: 'GET',
              url: '/microarch/activiti/undo/' + this.detail.pid,
              params: param
            })
              .then(res => {
                if (res.data.success == undefined || res.data.success) {
                  Toast.success('撤销成功');
                  this.EhrUtils.updateFormState(
                    this,
                    `/hrtbm/${this.saveUrl}/updateFormState`,
                    this.detail.businessKey,
                    this.TbmCons.FormState.TOVOID
                  ).then(res => {
                    if (res.data.entity) {
                      this.goWorkFlowPage();
                    }
                  });
                } else {
                  Toast.fail(res.data.msg);
                }
              })
              .catch(_ => {
                Toast.fail('单据异常，不可操作，请重新刷新单据或者联系管理员。');
              });
          })
          .catch(() => {
            // on cancel
          });
      },
      goWorkFlowPage() {
        this.loading = Toast.loading({
          message: '同步中,请等待...',
          forbidClick: true,
          duration: 0
        });
        setTimeout(() => {
          Toast.clear();
          sessionStorage.removeItem('formData');
          this.$router.replace({
            name: 'workFlow/workFlowList'
          });
        }, 5000);
      },
      awaittransactProcess(option) {
        let activitiData = JSON.parse(sessionStorage.getItem('activitiData'));
        let deptAndPost = parseInt(sessionStorage.getItem('deptAndPost'));
        let variables = {};
        if (activitiData) {
          variables = {
            activitiData: {
              ...activitiData,
              deptAndPost
            }
          };
        }
        this.$myHttp({
          method: 'post',
          url: '/microarch/activiti/transact',
          data: {
            businessKey: this.detail.businessKey,
            taskId: this.detail.id,
            processInstanceId: this.detail.pid,
            variables,
            userName: sessionStorage.getItem('userName'),
            woCode: this.woCode
          }
        }).then(res => {
          if (res.data.msg) {
            Toast.fail({
              duration: 20000,
              message: res.data.msg
            });
          } else {
            this.$emit('check-before-process', option);
            this.transactData = res.data;
            this.detail.updateUrl = this.saveUrl;
            this.$router.push({
              name: 'approvalPanel',
              query: {
                transactData: this.transactData,
                waitforInfo: this.waitforInfo,
                detail: this.detail,
                isBack: this.isBack,
                detailPageName: this.detailPageName,
                applyChangeNo: this.applyChangeNo
              },
              params: {
                taskType: this.taskType,
                submitVisable: this.submitVisable,
                updateStateUrl: this.updateStateUrl,
                businessKey: this.businessInfo.id
              }
            });
          }
        });
      },
      async getWorkflow(option) {
        const mappingId = await this.$myHttp({
          url: '/microarch/activiti/mapping/findBywoCodeAndOrgDept',
          method: 'post',
          data: {
            entity: {
              woCode: this.woCode,
              orgId: this.businessInfo.pkOrg,
              deptId: this.businessInfo.pkDept
            }
          }
        }).then(res => {
          if (res.status == 200) {
            return res.data.ext.mappingId;
          }
        });

        let activitiData = JSON.parse(sessionStorage.getItem('activitiData'));
        let deptAndPost = parseInt(sessionStorage.getItem('deptAndPost'));
        let variables = {};
        if (activitiData) {
          variables = {
            activitiData: {
              ...activitiData,
              deptAndPost
            }
          };
        }
        this.$myHttp({
          url: '/microarch/activiti/startWorkflow',
          method: 'post',
          data: {
            businessKey: this.businessInfo.id,
            processInstanceName: this.processName,
            userName: sessionStorage.getItem('userName'),
            variables,
            mappingId: mappingId
          }
        }).then(res => {
          if (res.status == 200) {
            this.detail = {
              id: res.data.taskId,
              name: res.data.taskName,
              pid: res.data.processInstanceId,
              businessKey: this.businessInfo.id,
              taskType: 'waitfor',
              priority: res.data.priority,
              endProcessType: 'add',
              updateUrl: this.saveUrl
            };
            this.waitforInfo = {
              name: res.data.taskName,
              isCountersign: res.data.countersign,
              priority: res.data.priority,
              businessInfo: this.processName
            };
            this.awaittransactProcess(option);
          }
        });
      },
      // syncProcess (option) {
      //   this.$myHttp({
      //     url: '/hrpm/comm/syncProcess',
      //     method: 'post',
      //     params: {
      //       formId: this.detail.businessKey,
      //       pid: this.detail.pid
      //     }
      //   }).then(res => {
      //     this.goWorkFlowPage();
      //   });
      // },
      // goWorkFlowPage() {
      //   this.loading = Toast.loading({
      //     message: '同步中,请等待...',
      //     forbidClick: true,
      //     duration: 0
      //   });
      //   setTimeout(() => {
      //     Toast.clear();
      //     this.$router.replace({
      //       name: 'workFlow/workFlowList',
      //       query: {
      //         type: 'transated'
      //       }
      //     });
      //   }, 5000);
      // }
    },
    filters: {
      priorityFil(val) {
        const colorMap = {
          50: ['green', '一般'],
          100: ['orange', '紧急'],
          150: ['red', '特急']
        };

        let color = colorMap[val] || ['green', '一般'];

        return color[1];
      }
    }
  };
</script>
